import { graphql } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";

import React, { useState, useEffect } from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";
import Button from "@atoms/Button/Button";
import Section from "@atoms/Section/Section";
import Footer from "@components/Footer/Footer";
import LightBanner from "@components/LightBanner/LightBanner";
import AmbassadorList from "@components/AmbassadorList";
import HeaderMinimal from "@components/HeaderMinimal/HeaderMinimal";
import HeaderNav from "@components/HeaderNavV2/HeaderNav";
import LanguageNotSupported from "@components/LanguageNotSupported";
import VideoBackgroundWithContent from "@components/VideoBackgroundWithContent";
import Layout from "@components/layout";
import Seo from "@components/seo";
import { useLocale } from "@hooks";
import { withLocale, withTracking } from "@hocs";
import { useTracking } from '@hooks';

export const query = graphql`
    query($language: String!, $customLangKey: AEPSYCMS_I18NLocaleCode!) {
        locales: allLocale(
            filter: { ns: { in: ["index"] }, language: { eq: $language } }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        headerMobileImage: file(
            relativePath: { eq: "inspiration/header-inspiration-mobile.jpg" }
        ) {
            childImageSharp {
                fluid(maxWidth: 375, quality: 90) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        headerDesktopImage: file(
            relativePath: { eq: "inspiration/header-inspiration-desktop.jpg" }
        ) {
            childImageSharp {
                fluid(quality: 90, maxWidth: 1440) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        aepsyCms {
            stories(
                locale: $customLangKey
                pagination: { limit: 100 }
                sort: "createdAt:desc"
                filters: {author: {type: {eq: "ambassador"}}}
            ) {
                slug
                title
                locale
                type
                coverPicture {
                    url
                    urlSharp {
                        childImageSharp {
                            fluid(maxWidth: 300, quality: 80) {
                                ...GatsbyImageSharpFluid_withWebp
                            }
                        }
                    }
                }
            }
        }
    }
`;

const InspirationPage: React.FC<any> = ({ data }) => {
	const { t } = useTranslation();
	const { currentLanguage } = useLocale();
	const [ambassadorList, setAmbassadorList] = useState([]);

	const imageSources = [
		data.headerMobileImage.childImageSharp.fluid,
		{
			...data.headerDesktopImage.childImageSharp.fluid,
			media: `(min-width: 750px)`,
		},
	];

	const { trackPageView } = useTracking();
	useEffect(() => {
		trackPageView();
	}, []);

	// TODO:
	// Updated this page as an example
	// created customLangKey using onCreatePage hook
	// and filtered both lang and ambassador type in query instead inside useEffect
	// that should reduce unused javascript
	// TODOTODO:
	// update other CMSpages as well as other templates the same way

	useEffect(() => {
		const stories = data.aepsyCms.stories;

		setAmbassadorList(stories);
	}, [data, currentLanguage]);

	return (
		<Layout>
			<Seo
				title={t("client.stories.header.title")}
				description={t("client.stories.header.subtitle")}
			/>
			<div className="global_theme-green">
				<HeaderNav theme="white" />
				<Section theme="light">
					<HeaderMinimal
						theme="light"
						title={t("client.stories.header.title")}
						subtitle={t("client.stories.header.subtitle")}
						btnPrimary={
							<AnchorLink offset={() => 40} href="#mehr">
								<Button
									variant="primary"
									theme="white"
									label={t("button.discover.now")}
									size="l"
								/>
							</AnchorLink>
						}
						imageSrc={imageSources}
					/>
				</Section>

				{/* Articles */}
				<div id="mehr">
					{ambassadorList.length > 0 ? (
						<Section spacingTop="l" container="large">
							<AmbassadorList list={ambassadorList} />
						</Section>
					) : (
						<Section spacingBottom="l">
							<LanguageNotSupported />
						</Section>
					)}
				</div>

				<Section spacing="m">
					<LightBanner
						title={t("resources.footer.title")}
						description={t("resources.footer.subtitle")}
						button={
							<a href="mailto:hey@aepsy.com">
								<Button
									variant="inline"
									theme="highlighted"
									label={t("button.contact.now")}
									iconArrow
								/>
							</a>
						}
					/>
				</Section>

				<Section container="large" spacing="m">
					<VideoBackgroundWithContent />
				</Section>

				{/* Footer */}
				<Footer />
			</div>
		</Layout>
	);
};

export default withTracking(withLocale(InspirationPage));
